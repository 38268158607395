import { css } from "lit";

export const stylesMessage = css`
  .message-wrapper {
    position: relative;
    display: inline-block; /* así no ocupa todo el ancho */
  }

.arrow_out_img {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 17x; /* Ajusta el tamaño según necesidad */
  height: 17px;
  background: transparent; /* Opcional: fondo blanco para que resalte */
  border-radius: 50%; /* Opcional: redondear */
  padding: 3px; /* Opcional: dar espacio interior */
  transition: transform 0.2s ease-in-out; /* Suaviza la animación */
}

.image-container {
  position: relative;
  width: fit-content; /* Ajusta al tamaño de la imagen */
  height: fit-content; /* Ajusta al tamaño de la imagen */
  max-width: 220px;
  max-height: 140px;
  margin: 12px auto;
  cursor: pointer;
}

.image-container:hover .arrow_out_img {
  transform: scale(1.12); /* Aumenta el tamaño un 20% */
}

.image-message {
  width: auto;
  height: auto;
  max-width: 220px;
  max-height: 140px;
  object-fit: cover; /* Ajusta la imagen manteniendo el tamaño especificado */
  border-radius: 10px;
}


  .message {
    margin: 0px;
    text-align: start;
    padding: 20px;
    max-width: 342px;
    width: auto;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    box-sizing: border-box;
  }

  .assistant {
    background: #f5f5f5;
    border-radius: 0px 10px 10px 10px;
    color: var(--Black_text, #1e1e1e);
  }

  .user {
    background: var(--Azul_chatbot, #1446ff);
    color: #fff;
    border-radius: 10px 0px 10px 10px;
  }

  /* Botón de copiar */
  .copy-button {
    position: absolute;
    top: 10px;
    left: 100%;
    margin-left: 8px; /* Espacio a la derecha de la burbuja */
    background: transparent;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    opacity: 0.7;
    transition: opacity 0.2s;
    padding: 0;
  }

  .copy-button:hover {
    opacity: 1;
  }

  .copy-button img {
    width: 100%;
    height: 100%;
  }

  /* Tooltip */
  .copied-tooltip {
    position: absolute;
    top: -28px;
    right: 0;
    background-color: #333;
    color: #fff;
    padding: 5px 8px;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    animation: fade-in-out 2s forwards;
    pointer-events: none;
  }

  @keyframes fade-in-out {
    0% {
      opacity: 0;
      transform: translateY(-5px);
    }
    15% {
      opacity: 1;
      transform: translateY(0);
    }
    85% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-5px);
    }
  }

  /* Loader */
  .loader {
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #1446ff;
    box-shadow: 8px 0 #1446ff, -8px 0 #1446ff;
    position: relative;
    animation: flash 0.5s ease-out infinite alternate;
  }

  @keyframes flash {
    0% {
      background-color: #c8d4ff;
      box-shadow: 8px 0 #c8d4ff, -8px 0 #1446ff;
    }
    50% {
      background-color: #1446ff;
      box-shadow: 8px 0 #c8d4ff, -8px 0 #c8d4ff;
    }
    100% {
      background-color: #c8d4ff;
      box-shadow: 8px 0 #1446ff, -8px 0 #c8d4ff;
    }
  }
`;
