import { css } from "lit";

export const stylesLayout = css`
  :host {
    display: block;
    position: fixed;
    bottom: -600px; /* Comienza fuera de la pantalla */
    right: 64px;
    width: min(100%, 416px);
    height: calc(100vh - 20px); /* Ajusta dinámicamente el alto a la pantalla */
    max-height: 620px; /* Asegura que no exceda los 600px en pantallas grandes */
    z-index: 9999;
    box-sizing: border-box;
    animation: slide-up 0.4s cubic-bezier(0.25, 0.1, 0.25, 1) forwards; /* Animación más suave */

    /* Adaptación para pantallas más pequeñas */
    @media screen and (max-width: 500px) {
      padding-left: 10px;
      padding-right: 10px;
      right: 0px;
      border-radius: 8px;
    }
      @media screen and (max-width: 1024px){
      animation:  slide-up-mobile 0.4s cubic-bezier(0.25, 0.1, 0.25, 1) forwards; /* Animación más suave */
      }
    }
  .layout-container {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    background: #fff;
    box-shadow: 1px 3px 20px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .header-container {
    width: 100%;
    height: 138px;
    max-width: 416px;
    max-height: 138px;
    flex-shrink: 0;
    background: var(--Fondo_sidebar, #fafafa);
    box-sizing: border-box;
    border-radius: 18px 18px 0px 0px; /* Bordes superiores redondeados */
  }

  .main-chat-container {
    width: 100%;
    height: 422px;
    max-width: 416px;
    max-height: 422px;
    background: #fff; /* Fondo blanco */
    overflow-y: auto; /* Habilita scroll si el contenido excede */
    box-sizing: border-box;
  }

  .footer-container {
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    max-width: 416px;
    max-height: 60px;
    flex-shrink: 0;
    background: var(--Fondo_sidebar, #fafafa);
  }

  /* Definición de la animación */
  @keyframes slide-up {
    0% {
      bottom: -600px; /* Fuera de la pantalla */
      opacity: 0; /* Invisible */
    }
    100% {
      bottom: 87px; /* Posición final */
      opacity: 1; /* Totalmente visible */
    }
  }
    /* Definición de la animación para pantallas pequeñas */
  @keyframes slide-up-mobile {
    0% {
      bottom: -600px; /* Fuera de la pantalla */
      opacity: 0; /* Invisible */
    }
    100% {
      bottom: calc(5vh); /* Centrado dinámicamente */
      opacity: 1; /* Totalmente visible */
    }
  }
`;
